<template>
<div>
  <v-row  class="d-md-none illu-left-col atelier">
    <v-col cols="12"  class="text-left"></v-col>
  </v-row>


    <v-row  class="pt-0">
      <v-col cols="12" sm="4" class="d-none d-md-flex text-left pa-0">
        <div class=" illu-left-col atelier"></div>
      </v-col>
    

    <v-col cols="12" sm="10" md="8" lg="7" class="mt-5 mx-auto" style="margin-right:25%; padding-bottom:200px;">
      <v-card outlined color="transparent" elevation="0">
        
        <v-col cols="12" class="text-left">
          <h1 class="font-flower text-center">Bienvenue dans l'atelier</h1>

          <v-col cols="12" md="10" lg="9" class="mx-auto">
          <p>
            Je te propose ici la création du mandala qui correspondra entièrement à ton energie (ou celle de la personne à qui il sera destiné),
            et qui viendra harmoniser ton lieu avec ta vibration. Pour que la magie opère, je vais élaborer une alchimie entre mon art 
            et les caractéristiques qui font de toi un être unique !
             <br><br>
            Pour cela j'ai besoin de quelques informations :
            <br><br>
            - Le type et la dimension du support<br>
            - La gamme de couleur parmi les 5 éléments<br>
            - Ta date de naissance
            
            <br><br>
            
            N'hésite pas aussi à me communiquer tout autre élément qui me permettra
            de me connecter et d'être inspiré en résonnance avec toi (photo, musique préférée, intention, mantra...) 

            <br><br>
            Ensuite, pour valider la commande, je demande un acompte de 20% du prix, 
            qui me permet d'acheter le matériel eventuel et de m'assurer de ton engagement.
            <br><br>
            Le reste du paiement se fait quand j'ai réalisé le mandala et que tu l'as validé. 
            Une fois les finitions terminées (vernis + attache) 
            il sera soigneusement emballé et envoyé à l'adresse indiquée lors de la commande.
          </p>
          </v-col>

        </v-col>

        <v-col cols="12" class="text-center">
          <v-divider class="my-5"/>

          <v-row>
              <v-col cols="12" class="pb-0 mx-auto">
                <v-card-title class="mb-5 font-flower commande-step-title" id="step-support">
                  1 - Choisis ton support
                </v-card-title>
              </v-col>

              <v-col cols="6" sm="6" md="4" class="pb-0 ml-auto div-circleShape"
                     @click="commande.support = 'bois'"
                     :class="commande.support == 'bois' ? 'selected' : ''">
                <div class="circleShape"></div>
                <v-card-title class="mb-5" :class="commande.support == 'bois' ? 'cyan--text' : ''">
                  Sur bois
                </v-card-title>
              </v-col>

              <v-col cols="6" sm="6" md="4" class="pb-0 mr-auto div-squareShape"
                     @click="commande.support = 'toile'"
                     :class="commande.support == 'toile' ? 'selected' : ''">
                <div class="squareShape"></div>
                <v-card-title class="mb-5" :class="commande.support == 'toile' ? 'cyan--text' : ''">
                  Sur toile
                </v-card-title>
              </v-col>
          </v-row>

          <!-- <v-expand-transition mode="out-in">
            <div v-if="commande.support != null">
              <v-divider class="my-5"/>

              <v-row class="pb-5">

                <v-col cols="12" class="pb-0 mx-auto">
                  <v-card-title class="mb-5 font-flower commande-step-title" id="step-colorRef">
                    2 - Choisis ta gamme de couleurs
                  </v-card-title>
                </v-col>

                <v-col cols="12" sm="6" md="5" lg="4" class="pb-0 ml-auto">
                  <v-btn x-large outlined rounded class="mb-1" 
                        :color="commande.colorRef=='exists' ? 'cyan' : ''" 
                        @click="commande.colorRef = 'exists'">
                    Parmi les fond existant
                  </v-btn>
                </v-col>

                <v-col cols="12" sm="6" md="5" lg="4" class="pb-0 mr-auto">
                  <v-btn x-large outlined rounded class="mb-1" 
                        :color="commande.colorRef=='element' ? 'cyan' : ''" 
                        @click="commande.colorRef = 'element'">
                    A partir des 5 éléments
                  </v-btn>
                </v-col>
              </v-row>
              </div>
          </v-expand-transition> -->


          <v-expand-transition mode="out-in">
            <div v-if="commande.support != null">
              <v-divider class="my-5"/>

              <v-card-subtitle class="font-flower commande-step-title">
                2 - Définis ton élément
              </v-card-subtitle>

              <v-card-text class="text-left mb-5 px-5">
                Le choix de l'élément va déterminer la gamme de couleur du mandala mais aussi
                orienter le type de géométrie et mon inspiration dans les tracés. Je t'invite à regarder quel élément 
                se rattache à ton signe astrologique pour faire ton choix, ainsi que leur symbolique si tu souhaites
                apporter l'énergie d'un élément en particulier dans ta vie.
              </v-card-text>
              
              <v-row>
                <!-- <v-col cols="2" class="pb-0 px-0 mx-auto" @click="commande.element='terre'" >
                  <v-img src="@/assets/img/commande/back-terre.jpg" 
                        class="img-rounded btn-element" :aspect-ratio="1/1"
                       :class="commande.element == 'terre' ? 'selected' : ''"/>
                </v-col>
                <v-col cols="2" class="pb-0 px-0 mx-auto" @click="commande.element='feu'">
                  <v-img src="@/assets/img/commande/back-feu.jpg" 
                         class="img-rounded btn-element" :aspect-ratio="1/1" 
                        :class="commande.element == 'feu' ? 'selected' : ''"/>
                </v-col>
                <v-col cols="2" class="pb-0 px-0 mx-auto" @click="commande.element='eau'">
                  <v-img src="@/assets/img/commande/back-eau.jpg" 
                         class="img-rounded btn-element" :aspect-ratio="1/1" 
                        :class="commande.element == 'eau' ? 'selected' : ''"/>
                </v-col>
                <v-col cols="2" class="pb-0 px-0 mx-auto" @click="commande.element='air'">
                  <v-img src="@/assets/img/commande/back-air.jpg" 
                         class="img-rounded btn-element" :aspect-ratio="1/1" 
                        :class="commande.element == 'air' ? 'selected' : ''"/>
                </v-col>
                <v-col cols="2" class="pb-0 px-0 mx-auto" @click="commande.element='ether'">
                  <v-img src="@/assets/img/commande/back-ether.jpg" 
                         class="img-rounded btn-element" :aspect-ratio="1/1" 
                        :class="commande.element == 'ether' ? 'selected' : ''"/>
                </v-col> -->
              </v-row>

              <v-row>
              <template v-for="(element, e) in elements">
                <v-col cols="2" class="pt-0 px-0 mx-auto" :key="e"> 
                  <v-card-title class="pa-0">{{ element.type }}</v-card-title>
                  <template v-for="(color, c) in element.colors">
                      <v-card :color="color" :key="c" class="text-center pa-2 mt-1"></v-card>
                  </template>
                </v-col>
              </template>
              </v-row>
              <div id="step-element"></div>
          
            </div>
          </v-expand-transition>

          
          <v-expand-transition mode="out-in">
            <div v-if="commande.colorRef == 'exists'">
              <v-divider class="my-5"/>

              <v-card-subtitle class="font-flower commande-step-title" id="step-exists">
                3 - Sélectionne le mandala qui t'inspire le plus
              </v-card-subtitle>
              <v-card-subtitle class="mb-5">
                Chaque mandala est unique ! <br>
                Mais si l'un de ces mandalas te plait particulièrement, je peux en créer un nouveau spécialement pour toi,
                en m'inspirant de celui que tu auras choisi...

              </v-card-subtitle>

              <v-row>
              <template v-for="(product, p) in products">
                <v-col cols="6" sm="4" md="3" lg="2" class="pt-0 px-1 mx-auto" :key="p"> 
                  <v-img class="img-rounded img-background" 
                        :src="baseUrl() + product.image" :aspect-ratio="1/1"
                        :class="commande.productRef == product._id ? 'selected' : ''"
                        @click="commande.productRef = product._id">
                  </v-img>
                  <v-card-subtitle class="pa-0">{{ product.name }}</v-card-subtitle>
                </v-col>
              </template>
              </v-row>
            </div>
          </v-expand-transition>

          <v-expand-transition mode="out-in">
            <div v-if="commande.productRef != null || commande.element != null">
              <v-divider class="my-5"/>

              <v-card-subtitle class="font-flower commande-step-title" id="step-size">
                3 -Définis la taille de ton mandala...
              </v-card-subtitle>

              <v-col cols="12" sm="11" md="10" lg="8" class="mx-auto">
              <v-slider v-model="commande.size" :step="20" 
                        :max="sizeMax" :min="sizeMin" color="cyan"
                        inverse-label :label="commande.size + ' cm'"></v-slider>
              </v-col>

              <div :style="'height: 330px;'" class="d-flex">
                <div class="circleCommande font-flower text-center" :class="commande.support == 'toile' ? 'support-square' : ''"
                    :style="'width:'+(commande.size*3)+'px; height:'+(commande.size*3)+'px; padding:'+(commande.size/2)+'px; padding-top:'+(commande.size*1.1)+'px;'">
                  <span class="cyan--text">{{ commande.size }} cm</span><br>
                  <small>{{ price }} €</small>
                </div>
              </div>

              <!-- <v-divider class="my-5"/>
              <v-card-subtitle class="mb-5 font-flower commande-step-title" id="step-chiffre">
                5 - Selon toi, quel chiffre te définit le mieux ?
              </v-card-subtitle>

              <template v-for="chiffre in [1,2,3,4,5,6,7,8,9]">
                <v-btn fab elevation="1" :key="chiffre" class="mx-1 btn-chiffre font-flower" 
                      @click="commande.chiffre = chiffre"
                      :color="commande.chiffre == chiffre ? 'cyan' : ''">
                      <b style="font-size:35px;">{{ chiffre }}</b>
                </v-btn>
              </template> -->

              <v-divider class="my-5"/>

              <v-card-subtitle class="pb-0 font-flower" style="font-size:1.6em;line-height:1.3em;">
                4 - Quelle est ta date de naissance ?
              </v-card-subtitle>
              <v-card-subtitle class="mb-5">
                <small>
                  Ta date de naissance (et sa réduction numérologique) me serviront de source d'inspiration pour l'ensemble du dessin,
                  (géométrie, structure, division et nombre de cercles..)
                </small>
              </v-card-subtitle>
              <v-row>
                <v-col cols="12" md="4" class="mx-auto">
                  <v-text-field flat outlined label="JJ/MM/AAAA" v-model="commande.birthday"></v-text-field>
                </v-col>
              </v-row>

              <v-divider class="my-5 pb-5"/>


              <v-row>
                <v-col cols="12" class="text-left py-0">
                  <v-card-title>
                    <v-icon>mdi-chevron-down</v-icon> Contact
                  </v-card-title>
                </v-col>
                <v-col cols="12" md="6" class="mx-auto py-0" :error-messages="formErrors['name']">
                  <v-text-field flat outlined label="Nom / prénom" v-model="commande.name"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="mx-auto py-0" :error-messages="formErrors['email']">
                  <v-text-field flat outlined label="E-mail" v-model="commande.email"></v-text-field>
                </v-col>
                <v-col cols="12" class="text-left py-0">
                  <v-card-title>
                    <v-icon>mdi-chevron-down</v-icon> Adresse de livraison
                  </v-card-title>
                </v-col>
                <v-col cols="12" md="6" class="mx-auto py-0" :error-messages="formErrors['streetAddress']">
                  <v-text-field flat outlined label="Adresse, n° rue, etc..." v-model="commande.streetAddress"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="mx-auto py-0" :error-messages="formErrors['postalCode']">
                  <v-text-field flat outlined label="Code postal" v-model="commande.postalCode"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="mx-auto py-0" :error-messages="formErrors['city']">
                  <v-text-field flat outlined label="Ville" v-model="commande.city"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="mx-auto py-0">
                  <v-autocomplete :hide-details="formErrors['country'] == null" :items="countries" 
                                  item-text="name" :error-messages="formErrors['country']"
                                  label="Pays" outlined v-model="commande.country"></v-autocomplete>
                </v-col>

                <v-col cols="12" class="mx-auto text-left">
                  <v-textarea outlined solo v-model="commande.message" flat
                              label="Quelque chose à ajouter ?">
                  </v-textarea>

                  <v-divider/>
                  <small>
                    Un <b class="orange--text">acompte de 20%</b> du prix indiqué sera nécessaire pour valider définitivement votre commande.
                  </small>
                  <v-divider/>
                  <small>
                    - Taille sélectionnée : <b>{{ commande.size }}cm</b>
                  </small>
                  <br>
                  <small>
                    - Calcul de l'acompte : <b class="orange--text">20%</b><b> de {{ price }}€</b> 
                    = <b class="orange--text">{{ (parseInt(price) * 20 /100).toFixed(0) }}€</b>
                  </small>
                  <v-divider/>
                  <small>
                    Vous pouvez regler l'acompte dès maintenant ou plus tard, en cliquant sur le lien de paiement qui se trouve dans votre mail de confirmation. Merci !
                  </small>
                  <v-divider/>
                  <small>
                    De mon côté, je commence à travailler sur une commande uniquement lorsque j'ai reçu le paiement de l'acompte, afin de m'assurer de votre engagement.
                  </small>
                  <v-divider/>

                  <br>

                  <v-btn color="green" block x-large dark @click="sendCommande">
                    <v-icon>mdi-check</v-icon> Envoyer ma commande
                  </v-btn>
                </v-col>
              </v-row>

              <v-alert v-for="(error, e) in formErrors" :key="e" color="red lighten-2" dark dense>
                <v-icon small>mdi-alert</v-icon> Erreur : {{ error.message }}
              </v-alert>

            </div>
          </v-expand-transition>

          <div id="step-end"></div>
        </v-col>
      </v-card>
    </v-col>
    </v-row>
</div>
</template>

<style>
  
</style>

<script>
import core from '../plugins/core.js'
import axios from "axios"
import router from "@/router/router"

import '@/assets/css/commande.css';
import countries from '../plugins/countries.json'

export default {
    name: "dialog-product",
    components: {
    },
    props: ['product'],
    data: function () {
        return {
          sizeMin: 60,
          sizeMax: 100,
          countries: [],
          commande : {
            support: null,
            colorRef: 'element',
            element: null,
            size: 60,
            productRef: null,
            chiffre: '',
            birthday: '',
            message: '',
            name: '',
            email: '',
            streetAddress: '',
            postalCode: '',
            country: '',
            city: '',
          },
          products: [],
          formErrors: [],
          elements: [
                    { type: 'terre',  colors: ['amber accent-1',      'orange',           'amber darken-4',         'brown darken-2' ] }, 
                    { type: 'feu',    colors: ['amber',               'orange',           'red',                    'purple darken-2'] }, 
                    { type: 'eau',    colors: ['teal',                'cyan',             'blue',                   'indigo darken-3'] }, 
                    { type: 'air',    colors: ['blue lighten-3',      'blue lighten-1',   'deep-purple lighten-3',  'purple darken-1'] },
                    { type: 'ether',  colors: ['blue-grey lighten-3', 'purple lighten-1', 'purple darken-4',        'indigo darken-4'] },
                  ],
        };
    },
    mounted: async function(){
        this.$store.dispatch('app/incStat', '/commande')
        await this.$store.dispatch('app/fetchEntities', { entityType: "product" })
        this.products = this.$store.state.app.data.product

        this.countries = countries
        this.commande.country = this.countries[0].name
    },
    methods: {
        
      scrollTo: function(id){
        console.log("scrollTo", id)
        setTimeout(() => {
          if(document.getElementById('step-'+id) == null) return
          let pageHeight = document.getElementById('step-'+id).offsetTop
          this.$vuetify.goTo(pageHeight)
        }, 500)
        
      },

      sendCommande: async function(){
        
        const { data } = await axios.post('/commande/create', this.commande)
        if(data.error == false){
          console.log("save commande ok", data)
          this.$store.dispatch('app/openDialogMailDev', data.emailParams)
          router.push("/payment/commande/accompte/" + data.commande._id)
        }else{
          this.formErrors = []
          if(data.validateErrors != null){
            data.validateErrors.details.forEach((error)=>{
              console.log("error.message", error.message)
              this.formErrors[error.path[0]] = error.message
              console.log("this.formErrors", this.formErrors)
            })
          }
        }
      },
      baseUrl(){ return core.baseUrl() }
    },
    computed:{
      price(){ 
        if(this.commande.size == 60) return 377
        if(this.commande.size == 80) return 610
        if(this.commande.size == 100) return 987
        return ((this.commande.size * this.factor)).toFixed(0) 
      },
      factor(){
        return this.commande.support == "bois" ? 3.14 : 4
      }
    },
    watch: {
      'commande.support': { immediate: true, 
          async handler () {
            if(this.commande.support != null)
              this.scrollTo('end')
          }
      },
      'commande.colorRef': { immediate: true, 
          async handler () {
            console.log("watch commande.colorRef", this.commande.colorRef)
            if(this.commande.colorRef != null){
              if(this.commande.colorRef == "exists") this.scrollTo('exists')
              if(this.commande.colorRef == "element") this.scrollTo('element')
            }
          }
      },
      'commande.productRef': { immediate: true, 
          async handler () {
            console.log("watch commande.support", this.commande.productRef)
            if(this.commande.productRef != null)
              this.scrollTo('size')
          }
      },
      'commande.element': { immediate: true, 
          async handler () {
            console.log("watch commande.element", this.commande.element)
            if(this.commande.element != null)
              this.scrollTo('size')
          }
      },
    }, 
};
</script>